import H2 from "@material-tailwind/react/Heading2";
import LeadText from "@material-tailwind/react/LeadText";
// import Button from "@material-tailwind/react/Button";

export default function Header() {
  return (
    <div className="relative pt-16 pb-32 flex content-center items-center justify-center h-screen">
      <div className="bg-landing-background bg-cover bg-center absolute top-0 w-full h-full" />
      <div className="container max-w-8xl relative mx-auto">
        <div className="items-center flex flex-wrap">
          <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
            <H2 color="white">ONLINE ONE SERVICES</H2>
            <div className="text-gray-100">
              <LeadText color="white">GETTING YOU ONLINE READY!</LeadText>
            </div>
            {/* <div className="flex content-center items-center justify-center">
              <a
                href=""
                // target="_blank"
                rel="noreferrer"
              >
                <Button
                  color="transparent"
                  className="bg-white text-black m-6"
                  ripple="dark"
                >
                  Contact Us
                </Button>
              </a>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
